import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/templates/Layout";
import Page from "../components/molecules/NodeTypes/page";
import {currentLanguageByPath, getGreeting} from "../helpers/MainHelper";

const title = getGreeting(currentLanguageByPath())

const IndexPage = ({data}) => (
    <Layout
        lang={'nl'}
        class={'home'}
        activeMenu={'home'}
        activeNid={1}
        metatags={data.drupal.page.url.metatags}
    >
        <Page nodeContent={data.drupal.page} title={title} />
    </Layout>
);
export default IndexPage;

export const query = graphql`
query indexPage {
    drupal {
        page:entityById(id: "1", langcode: NL, entityType: NODE) {
            ... on Drupal_NodePage {
                nid
                title
                body 
                fieldOpening
                path {
                    alias
                }
                url {
                    ... on Drupal_EntityUrl {
                        metatags {
                            tag
                            attributes {
                                key
                                value
                            }
                        }
                    }
                }
            }
        }
    }
}
`;

